import React from 'react';
import { motion } from 'framer-motion';

// Skill Data
const skills = [
  { name: 'Prompt Writing for AI', description: 'Creating AI prompts to optimize model interaction.' },
  { name: 'Python', description: 'Automation, data analysis, and web development.' },
  { name: 'IT Security', description: 'Securing data and systems from cyber threats.' },
  { name: 'SEO', description: 'Optimizing websites for search engines to increase visibility.' },
];

const fadeInUp = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

const App = () => {
  return (
    <div className="bg-[#2D3250] text-white font-poppins">
      {/* Navbar */}
      <header className="fixed top-0 left-0 w-full bg-[#424769] z-10 shadow-md">
        <nav className="container mx-auto flex justify-between items-center py-4 px-6">
          <motion.a href="#hero" className="font-bold text-xl text-[#F6B17A]" initial="hidden" animate="visible" variants={fadeInUp}>
            Hecate
          </motion.a>
          <ul className="flex space-x-6">
            <motion.li initial="hidden" animate="visible" variants={fadeInUp} transition={{ delay: 0.2 }}>
              <a href="#about" className="hover:text-[#F6B17A] text-[#7077A1]">About</a>
            </motion.li>
            <motion.li initial="hidden" animate="visible" variants={fadeInUp} transition={{ delay: 0.4 }}>
              <a href="#skills" className="hover:text-[#F6B17A] text-[#7077A1]">Skills</a>
            </motion.li>
            <motion.li initial="hidden" animate="visible" variants={fadeInUp} transition={{ delay: 0.6 }}>
              <a href="#projects" className="hover:text-[#F6B17A] text-[#7077A1]">Projects</a>
            </motion.li>
            <motion.li initial="hidden" animate="visible" variants={fadeInUp} transition={{ delay: 0.8 }}>
              <a href="#contact" className="hover:text-[#F6B17A] text-[#7077A1]">Contact</a>
            </motion.li>
          </ul>
        </nav>
      </header>

      {/* Hero Section */}
      <section id="hero" className="min-h-screen flex items-center justify-center bg-[#424769]">
        <motion.div className="text-center px-4" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          <img src="https://scontent.fcgk6-3.fna.fbcdn.net/v/t39.30808-6/448889518_122097414584375945_2118606259766214303_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeEthsDNrSkq51AyzdBSjeuEYpTrAHnTcIRilOsAedNwhAyPDu6jY43K5MOnIRMOHl-MGSU9YfDgDZn9qN7lQ0pW&_nc_ohc=94Z5cPhucjQQ7kNvgGUoBbl&_nc_zt=23&_nc_ht=scontent.fcgk6-3.fna&_nc_gid=AdY6kxDX3fSBf5aYbndWamO&oh=00_AYCDNPTWcoAx0alMztiHve8Z940tZM1yDsEUdB_UL5mXIg&oe=67244CDF" alt="Hecate" className="rounded-full w-40 h-40 mx-auto mb-6 shadow-lg" />
          <h1 className="text-5xl font-bold mb-4 text-[#F6B17A]">Hello, I'm Hecate</h1>
          <p className="text-lg text-[#7077A1]">I specialize in Python, Prompt Writing, and IT Security</p>
          <motion.a href="#about" className="mt-8 inline-block bg-[#F6B17A] text-white py-2 px-6 rounded-md shadow-md hover:bg-[#e69a63]" whileHover={{ scale: 1.1 }}>
            Learn More
          </motion.a>
        </motion.div>
      </section>

      {/* About Section */}
      <section id="about" className="container mx-auto py-20 px-6">
        <motion.div className="text-center mb-12" initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeInUp}>
          <h2 className="text-4xl font-bold text-[#F6B17A]">About Me</h2>
          <p className="text-lg text-[#7077A1] mt-4">I'm a Python learner with a passion for technology and security.</p>
        </motion.div>
      </section>

      {/* Skills Section */}
      <section id="skills" className="container mx-auto py-20 px-6 bg-[#424769]">
        <motion.div className="text-center mb-12" initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeInUp}>
          <h2 className="text-4xl font-bold text-[#F6B17A]">Skills</h2>
        </motion.div>
        <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10" initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeInUp}>
          {skills.map((skill, index) => (
            <motion.div
              key={index}
              className="text-center p-6 bg-[#F6B17A] shadow-md rounded-lg text-[#2D3250] flex flex-col justify-between"
              whileHover={{ scale: 1.05 }}
            >
              <h3 className="text-xl font-semibold">{skill.name}</h3>
              <p className="text-sm text-[#2D3250] mt-2">{skill.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </section>

      {/* Projects Section */}
      <section id="projects" className="container mx-auto py-20 px-6">
        <motion.div className="text-center mb-12" initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeInUp}>
          <h2 className="text-4xl font-bold text-[#F6B17A]">Projects</h2>
        </motion.div>
        <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10" initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeInUp}>
          {/* Add project data dynamically here */}
        </motion.div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="container mx-auto py-20 px-6 bg-[#424769] text-center">
        <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeInUp}>
          <h2 className="text-4xl font-bold text-[#F6B17A]">Get in Touch</h2>
          <p className="text-lg text-[#7077A1] mt-4">Feel free to reach out if you're interested in collaborating or just want to say hi.</p>
          <motion.a href="mailto:hecate@example.com" className="mt-8 inline-block bg-[#F6B17A] text-white py-2 px-6 rounded-md shadow-md hover:bg-[#e69a63]" whileHover={{ scale: 1.1 }}>
            Contact Me
          </motion.a>
        </motion.div>
      </section>

      {/* Footer */}
      <footer className="bg-[#2D3250] py-4 text-center border-t border-[#7077A1]">
        <p className="text-[#7077A1]">&copy; 2024 Hecate. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;
